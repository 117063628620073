/** Thursday 25th of May 2017 07:13:42 AM (core) **/

#mega-menu-wrap-main_menu, #mega-menu-wrap-main_menu #mega-menu-main_menu, #mega-menu-wrap-main_menu #mega-menu-main_menu ul.mega-sub-menu, #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item, #mega-menu-wrap-main_menu #mega-menu-main_menu a.mega-menu-link {
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
  -ms-border-radius: 0 0 0 0;
  -o-border-radius: 0 0 0 0;
  border-radius: 0 0 0 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  background: none;
  border: 0;
  bottom: auto;
  box-sizing: border-box;
  clip: auto;
  color: #666;
  display: block;
  float: none;
  font-family: inherit;
  font-size: 14px;
  height: auto;
  left: auto;
  line-height: 1.7;
  list-style-type: none;
  margin: 0;
  min-height: 0;
  opacity: 1;
  outline: none;
  overflow: visible;
  padding: 0;
  position: relative;
  right: auto;
  text-align: left;
  text-decoration: none;
  text-transform: none;
  top: auto;
  vertical-align: baseline;
  visibility: inherit;
  width: auto;
}
#mega-menu-wrap-main_menu:before, #mega-menu-wrap-main_menu #mega-menu-main_menu:before, #mega-menu-wrap-main_menu #mega-menu-main_menu ul.mega-sub-menu:before, #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item:before, #mega-menu-wrap-main_menu #mega-menu-main_menu a.mega-menu-link:before, #mega-menu-wrap-main_menu:after, #mega-menu-wrap-main_menu #mega-menu-main_menu:after, #mega-menu-wrap-main_menu #mega-menu-main_menu ul.mega-sub-menu:after, #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item:after, #mega-menu-wrap-main_menu #mega-menu-main_menu a.mega-menu-link:after {
  display: none;
}
#mega-menu-wrap-main_menu {
  background: #fff;
  -webkit-border-radius: 1px 0px 0px 0px;
  -moz-border-radius: 1px 0px 0px 0px;
  -ms-border-radius: 1px 0px 0px 0px;
  -o-border-radius: 1px 0px 0px 0px;
  border-radius: 1px 0px 0px 0px;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu {
  visibility: visible;
  text-align: left;
  padding: 0px 0px 0px 0px;
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu {
    background: #222;
  }
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu {
    padding: 0;
  }
}
#mega-menu-wrap-main_menu #mega-menu-main_menu a.mega-menu-link {
  cursor: pointer;
  display: inline;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu a.mega-menu-link .mega-description-group {
  vertical-align: middle;
  display: inline-block;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu a.mega-menu-link .mega-description-group .mega-menu-title, #mega-menu-wrap-main_menu #mega-menu-main_menu a.mega-menu-link .mega-description-group .mega-menu-description {
  line-height: 1.5;
  display: block;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu a.mega-menu-link .mega-description-group .mega-menu-description {
  font-style: italic;
  font-size: 0.8em;
  text-transform: none;
  font-weight: normal;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item.mega-icon-top > a.mega-menu-link {
  display: table-cell;
  vertical-align: middle;
  line-height: initial;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item.mega-icon-top > a.mega-menu-link:before {
  display: block;
  margin: 0 0 6px 0;
  text-align: center;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item.mega-icon-top > a.mega-menu-link > span.mega-title-below {
  display: inline-block;
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item.mega-icon-top > a.mega-menu-link {
    display: block;
    line-height: 40px;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item.mega-icon-top > a.mega-menu-link:before {
    display: inline-block;
    margin: 0 6px 0 0;
    text-align: left;
  }
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item.mega-icon-right > a.mega-menu-link:before {
  float: right;
  margin: 0 0 0 6px;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-animating > ul.mega-sub-menu {
  pointer-events: none;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-disable-link > a.mega-menu-link, #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-megamenu li.mega-disable-link > a.mega-menu-link {
  cursor: default;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item-has-children.mega-disable-link > a.mega-menu-link {
  cursor: pointer;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu.mega-keyboard-navigation + .mega-menu-toggle.mega-menu-open, #mega-menu-wrap-main_menu #mega-menu-main_menu.mega-keyboard-navigation a:focus, #mega-menu-wrap-main_menu #mega-menu-main_menu.mega-keyboard-navigation input:focus {
  -webkit-box-shadow: inset 0px 0px 3px 1px #0ff;
  -moz-box-shadow: inset 0px 0px 3px 1px #0ff;
  -ms-box-shadow: inset 0px 0px 3px 1px #0ff;
  -o-box-shadow: inset 0px 0px 3px 1px #0ff;
  box-shadow: inset 0px 0px 3px 1px #0ff;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu p {
  margin-bottom: 10px;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu input, #mega-menu-wrap-main_menu #mega-menu-main_menu img {
  max-width: 100%;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item > ul.mega-sub-menu {
  display: block;
  visibility: hidden;
  opacity: 1;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="fade"] li.mega-menu-item > ul.mega-sub-menu {
  opacity: 0;
  transition-duration: 200ms;
  transition-timing-function: ease-in;
  transition-property: opacity, visibility;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="fade"].mega-no-js li.mega-menu-item:hover > ul.mega-sub-menu, #mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="fade"].mega-no-js li.mega-menu-item:focus > ul.mega-sub-menu, #mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="fade"] li.mega-menu-item.mega-toggle-on > ul.mega-sub-menu, #mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="fade"] li.mega-menu-item.mega-menu-megamenu.mega-toggle-on ul.mega-sub-menu {
  opacity: 1;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="fade_up"] li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu, #mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="fade_up"] li.mega-menu-item.mega-menu-flyout ul.mega-sub-menu {
  opacity: 0;
  margin-top: 10px;
  transition-duration: 200ms;
  transition-timing-function: ease-in;
  transition-property: opacity, margin-top, visibility;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="fade_up"].mega-no-js li.mega-menu-item:hover > ul.mega-sub-menu, #mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="fade_up"].mega-no-js li.mega-menu-item:focus > ul.mega-sub-menu, #mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="fade_up"] li.mega-menu-item.mega-toggle-on > ul.mega-sub-menu, #mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="fade_up"] li.mega-menu-item.mega-menu-megamenu.mega-toggle-on ul.mega-sub-menu {
  opacity: 1;
  margin-top: 0;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="slide_up"] li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu, #mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="slide_up"] li.mega-menu-item.mega-menu-flyout ul.mega-sub-menu {
  margin-top: 10px;
  transition-duration: 200ms;
  transition-timing-function: ease-in;
  transition-property: margin-top, visibility;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="slide_up"].mega-no-js li.mega-menu-item:hover > ul.mega-sub-menu, #mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="slide_up"].mega-no-js li.mega-menu-item:focus > ul.mega-sub-menu, #mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="slide_up"] li.mega-menu-item.mega-toggle-on > ul.mega-sub-menu, #mega-menu-wrap-main_menu #mega-menu-main_menu[data-effect="slide_up"] li.mega-menu-item.mega-menu-megamenu.mega-toggle-on ul.mega-sub-menu {
  margin-top: 0;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu.mega-no-js li.mega-menu-item:hover > ul.mega-sub-menu, #mega-menu-wrap-main_menu #mega-menu-main_menu.mega-no-js li.mega-menu-item:focus > ul.mega-sub-menu, #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item.mega-toggle-on > ul.mega-sub-menu, #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item.mega-menu-megamenu.mega-toggle-on ul.mega-sub-menu {
  visibility: visible;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item.mega-menu-megamenu ul.mega-sub-menu ul.mega-sub-menu {
  visibility: inherit;
  opacity: 1;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item a[class^='dashicons']:before {
  font-family: dashicons;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item a.mega-menu-link:before {
  display: inline-block;
  font: inherit;
  font-family: dashicons;
  position: static;
  margin: 0 6px 0 0px;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: inherit;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item.mega-hide-text a.mega-menu-link:before {
  margin: 0;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item.mega-hide-text li.mega-menu-item a.mega-menu-link:before {
  margin: 0 6px 0 0;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-align-bottom-left.mega-toggle-on > a.mega-menu-link {
  -webkit-border-radius: 1px 0px 0 0;
  -moz-border-radius: 1px 0px 0 0;
  -ms-border-radius: 1px 0px 0 0;
  -o-border-radius: 1px 0px 0 0;
  border-radius: 1px 0px 0 0;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-align-bottom-right > ul.mega-sub-menu {
  right: 0;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-align-bottom-right.mega-toggle-on > a.mega-menu-link {
  -webkit-border-radius: 1px 0px 0 0;
  -moz-border-radius: 1px 0px 0 0;
  -ms-border-radius: 1px 0px 0 0;
  -o-border-radius: 1px 0px 0 0;
  border-radius: 1px 0px 0 0;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu.mega-menu-item {
  position: static;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item {
  margin: 0 0px 0 0;
  display: inline-block;
  height: auto;
  vertical-align: middle;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item.mega-item-align-right {
  float: right;
}
@media only screen and (min-width: 993px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item.mega-item-align-right {
    margin: 0 0 0 0px;
  }
}
@media only screen and (min-width: 993px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item.mega-item-align-float-left {
    float: left;
  }
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item.mega-toggle-on > a.mega-menu-link, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item > a.mega-menu-link:hover, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item > a.mega-menu-link:focus {
  background: #f1f1f1;
  color: #222;
  font-weight: bold;
  text-decoration: none;
  border-color: #fff;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item.mega-current-menu-ancestor > a.mega-menu-link, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item.mega-current-page-ancestor > a.mega-menu-link {
  background: #f1f1f1;
  color: #222;
  font-weight: bold;
  text-decoration: none;
  border-color: #fff;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item > a.mega-menu-link {
  border-top: 0px solid #fff;
  border-left: 0px solid #fff;
  border-right: 0px solid #fff;
  border-bottom: 0px solid #fff;
  outline: none;
  text-decoration: none;
  padding: 0px 10px 0px 10px;
  line-height: 50px;
  font-weight: bold;
  height: 50px;
  vertical-align: baseline;
  text-align: left;
  width: auto;
  display: block;
  color: #222;
  text-transform: uppercase;
  text-decoration: none;
  background: rgba(0, 0, 0, 0);
  -webkit-border-radius: 1px 0px 0px 0px;
  -moz-border-radius: 1px 0px 0px 0px;
  -ms-border-radius: 1px 0px 0px 0px;
  -o-border-radius: 1px 0px 0px 0px;
  border-radius: 1px 0px 0px 0px;
  font-family: inherit;
  font-size: 14px;
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item > a.mega-menu-link {
    text-align: left;
    color: #fff;
    font-size: 14px;
  }
}
@media only screen and (min-width: 993px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item.mega-multi-line > a.mega-menu-link {
    line-height: inherit;
    display: table-cell;
    vertical-align: middle;
  }
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item.mega-multi-line > a.mega-menu-link br {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item {
    display: list-item;
    margin: 0;
    clear: both;
    border: 0;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item.mega-item-align-right {
    float: none;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item > a.mega-menu-link {
    -webkit-border-radius: 0 0 0 0 0 0 0;
    -moz-border-radius: 0 0 0 0 0 0 0;
    -ms-border-radius: 0 0 0 0 0 0 0;
    -o-border-radius: 0 0 0 0 0 0 0;
    border-radius: 0 0 0 0 0 0 0;
    border: 0;
    margin: 0;
    line-height: 40px;
    height: 40px;
    padding: 0 10px;
  }
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu {
  z-index: 999;
  -webkit-border-radius: 0px 0px 0px 0px;
  -moz-border-radius: 0px 0px 0px 0px;
  -ms-border-radius: 0px 0px 0px 0px;
  -o-border-radius: 0px 0px 0px 0px;
  border-radius: 0px 0px 0px 0px;
  background: #f1f1f1;
  padding: 0px 0px 0px 0px;
  position: absolute;
  width: 100%;
  border-top: 0px solid #fff;
  border-left: 0px solid #fff;
  border-right: 0px solid #fff;
  border-bottom: 0px solid #fff;
  left: 0;
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu {
    float: left;
    position: static;
    width: 100%;
  }
}
@media only screen and (min-width: 993px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-1-of-1 {
    width: 100%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-1-of-2 {
    width: 50%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-2-of-2 {
    width: 100%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-1-of-3 {
    width: 33.33333%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-2-of-3 {
    width: 66.66667%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-3-of-3 {
    width: 100%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-1-of-4 {
    width: 25%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-2-of-4 {
    width: 50%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-3-of-4 {
    width: 75%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-4-of-4 {
    width: 100%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-1-of-5 {
    width: 20%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-2-of-5 {
    width: 40%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-3-of-5 {
    width: 60%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-4-of-5 {
    width: 80%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-5-of-5 {
    width: 100%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-1-of-6 {
    width: 16.66667%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-2-of-6 {
    width: 33.33333%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-3-of-6 {
    width: 50%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-4-of-6 {
    width: 66.66667%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-5-of-6 {
    width: 83.33333%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-6-of-6 {
    width: 100%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-1-of-7 {
    width: 14.28571%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-2-of-7 {
    width: 28.57143%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-3-of-7 {
    width: 42.85714%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-4-of-7 {
    width: 57.14286%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-5-of-7 {
    width: 71.42857%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-6-of-7 {
    width: 85.71429%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-7-of-7 {
    width: 100%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-1-of-8 {
    width: 12.5%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-2-of-8 {
    width: 25%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-3-of-8 {
    width: 37.5%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-4-of-8 {
    width: 50%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-5-of-8 {
    width: 62.5%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-6-of-8 {
    width: 75%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-7-of-8 {
    width: 87.5%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-8-of-8 {
    width: 100%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-1-of-9 {
    width: 11.11111%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-2-of-9 {
    width: 22.22222%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-3-of-9 {
    width: 33.33333%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-4-of-9 {
    width: 44.44444%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-5-of-9 {
    width: 55.55556%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-6-of-9 {
    width: 66.66667%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-7-of-9 {
    width: 77.77778%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-8-of-9 {
    width: 88.88889%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu li.mega-menu-columns-9-of-9 {
    width: 100%;
  }
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu .mega-description-group .mega-menu-description {
  margin: 5px 0;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item ul.mega-sub-menu {
  clear: both;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item ul.mega-sub-menu li.mega-menu-item ul.mega-sub-menu {
  margin-left: 10px;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item ul.mega-sub-menu li.mega-menu-item ul.mega-sub-menu li.mega-menu-item ul.mega-sub-menu {
  margin-left: 20px;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item {
  color: #666;
  font-family: inherit;
  font-size: 14px;
  display: block;
  float: left;
  clear: none;
  padding: 15px 15px 15px 15px;
  vertical-align: top;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item.mega-menu-clear {
  clear: left;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item h4.mega-block-title {
  color: #555;
  font-family: inherit;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 5px 0px;
  vertical-align: top;
  display: block;
  border-top: 0px solid #555;
  border-left: 0px solid #555;
  border-right: 0px solid #555;
  border-bottom: 0px solid #555;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link {
  color: #555;
  font-family: inherit;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  vertical-align: top;
  display: block;
  border-top: 0px solid #555;
  border-left: 0px solid #555;
  border-right: 0px solid #555;
  border-bottom: 0px solid #555;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link:hover, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link:focus {
  color: #555;
  font-weight: bold;
  text-decoration: none;
  background: rgba(0, 0, 0, 0);
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link {
  color: #666;
  font-family: inherit;
  font-size: 14px;
  text-transform: none;
  text-decoration: none;
  font-weight: normal;
  margin: 0;
  padding: 0px 0px 0px 0px;
  vertical-align: top;
  display: block;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link:hover, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item li.mega-menu-item > a.mega-menu-link:focus {
  color: #666;
  font-weight: normal;
  text-decoration: none;
  background: rgba(0, 0, 0, 0);
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu {
    border: 0;
    padding: 10px;
    -webkit-border-radius: 0 0 0 0;
    -moz-border-radius: 0 0 0 0;
    -ms-border-radius: 0 0 0 0;
    -o-border-radius: 0 0 0 0;
    border-radius: 0 0 0 0;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item {
    width: 50%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu > li:nth-child(odd) {
    clear: left;
  }
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu.mega-no-headers > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link {
  color: #666;
  font-family: inherit;
  font-size: 14px;
  text-transform: none;
  text-decoration: none;
  font-weight: normal;
  margin: 0;
  border: 0;
  padding: 0px 0px 0px 0px;
  vertical-align: top;
  display: block;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu.mega-no-headers > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link:hover, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu.mega-no-headers > ul.mega-sub-menu > li.mega-menu-item > a.mega-menu-link:focus {
  color: #666;
  font-weight: normal;
  text-decoration: none;
  background: rgba(0, 0, 0, 0);
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu {
  z-index: 999;
  position: absolute;
  width: 200px;
  border-top: 0px solid #fff;
  border-left: 0px solid #fff;
  border-right: 0px solid #fff;
  border-bottom: 0px solid #fff;
  padding: 0px 0px 0px 0px;
  background: #222;
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu {
    float: left;
    position: static;
    width: 100%;
    padding: 0;
    border: 0;
  }
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item {
    clear: both;
  }
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item a.mega-menu-link {
  display: block;
  background: #222;
  color: #fff;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  padding: 0px 10px 0px 10px;
  line-height: 35px;
  text-decoration: none;
  text-transform: none;
  vertical-align: baseline;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item:first-child > a.mega-menu-link {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item:first-child > a.mega-menu-link {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item:last-child > a.mega-menu-link {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item:last-child > a.mega-menu-link {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item a.mega-menu-link:hover, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item a.mega-menu-link:focus {
  background: #555;
  font-weight: normal;
  text-decoration: none;
  color: #fff;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item ul.mega-sub-menu {
  position: absolute;
  left: 100%;
  top: 0;
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item ul.mega-sub-menu {
    position: static;
    left: 0;
    width: 100%;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item ul.mega-sub-menu a.mega-menu-link {
    padding-left: 20px;
  }
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout.mega-menu-item-has-children > a.mega-menu-link:after, #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout li.mega-menu-item-has-children > a.mega-menu-link:after, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item-has-children > a.mega-menu-link:after {
  content: '\f140';
  display: inline-block;
  font-family: dashicons;
  margin: 0 0 0 6px;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  color: inherit;
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout.mega-menu-item-has-children a.mega-menu-link:after, #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout li.mega-menu-item-has-children a.mega-menu-link:after, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item-has-children a.mega-menu-link:after {
    float: right;
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout.mega-menu-item-has-children.mega-toggle-on > a.mega-menu-link:after, #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout li.mega-menu-item-has-children.mega-toggle-on > a.mega-menu-link:after, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item-has-children.mega-toggle-on > a.mega-menu-link:after {
    content: '\f142';
  }
  #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout.mega-menu-item-has-children.mega-hide-sub-menu-on-mobile > a.mega-menu-link:after, #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout li.mega-menu-item-has-children.mega-hide-sub-menu-on-mobile > a.mega-menu-link:after, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item-has-children.mega-hide-sub-menu-on-mobile > a.mega-menu-link:after {
    display: none;
  }
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout.mega-menu-item-has-children.mega-hide-arrow > a.mega-menu-link:after, #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout li.mega-menu-item-has-children.mega-hide-arrow > a.mega-menu-link:after, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item-has-children.mega-hide-arrow > a.mega-menu-link:after {
  display: none;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item li.mega-menu-item-has-children > a.mega-menu-link:after {
  content: '\f139';
  float: right;
  margin: 0;
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item li.mega-menu-item-has-children > a.mega-menu-link:after {
    content: '\f140';
  }
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout.mega-align-bottom-right li.mega-menu-item a.mega-menu-link {
  text-align: right;
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout.mega-align-bottom-right li.mega-menu-item a.mega-menu-link {
    text-align: left;
  }
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout.mega-align-bottom-right li.mega-menu-item a.mega-menu-link:before {
  float: right;
  margin: 0 0 0 6px;
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout.mega-align-bottom-right li.mega-menu-item a.mega-menu-link:before {
    float: left;
    margin: 0 6px 0 0;
  }
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout.mega-align-bottom-right li.mega-menu-item-has-children > a.mega-menu-link:after {
  content: '\f141';
  float: left;
  margin: 0;
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout.mega-align-bottom-right li.mega-menu-item-has-children > a.mega-menu-link:after {
    content: '\f140';
    float: right;
  }
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-flyout.mega-align-bottom-right ul.mega-sub-menu li.mega-menu-item ul.mega-sub-menu {
  left: -100%;
  top: 0;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu li[class^='mega-lang-item'] > a.mega-menu-link > img {
  display: inline;
}
#mega-menu-wrap-main_menu #mega-menu-main_menu a.mega-menu-link > img.wpml-ls-flag, #mega-menu-wrap-main_menu #mega-menu-main_menu a.mega-menu-link > img.iclflag {
  display: inline;
  margin-right: 8px;
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item.mega-hide-on-mobile, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item.mega-hide-on-mobile {
    display: none;
  }
}
@media only screen and (min-width: 993px) {
  #mega-menu-wrap-main_menu #mega-menu-main_menu li.mega-menu-item.mega-hide-on-desktop, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-item.mega-hide-on-desktop {
    display: none;
  }
}
#mega-menu-wrap-main_menu .mega-menu-toggle {
  display: none;
  z-index: 1;
  cursor: pointer;
  background: #eee;
  -webkit-border-radius: 2px 2px 2px 2px;
  -moz-border-radius: 2px 2px 2px 2px;
  -ms-border-radius: 2px 2px 2px 2px;
  -o-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
  line-height: 40px;
  height: 40px;
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  outline: none;
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu .mega-menu-toggle {
    display: block;
  }
}
#mega-menu-wrap-main_menu .mega-menu-toggle .mega-toggle-block {
  text-align: left;
  display: inline-block;
  height: 100%;
}
#mega-menu-wrap-main_menu .mega-menu-toggle .mega-toggle-block-left {
  float: left;
  margin-left: 6px;
}
#mega-menu-wrap-main_menu .mega-menu-toggle .mega-toggle-block-center {
  float: none;
  margin-left: 3px;
  margin-right: 3px;
}
#mega-menu-wrap-main_menu .mega-menu-toggle .mega-toggle-block-right {
  float: right;
  margin-right: 6px;
}
@media only screen and (max-width: 992px) {
  #mega-menu-wrap-main_menu .mega-menu-toggle + #mega-menu-main_menu {
    display: none;
  }
  #mega-menu-wrap-main_menu .mega-menu-toggle + #mega-menu-main_menu li.mega-menu-item > ul.mega-sub-menu {
    display: none;
    visibility: visible;
    opacity: 1;
  }
  #mega-menu-wrap-main_menu .mega-menu-toggle + #mega-menu-main_menu li.mega-menu-item.mega-toggle-on > ul.mega-sub-menu, #mega-menu-wrap-main_menu .mega-menu-toggle + #mega-menu-main_menu li.mega-menu-item.mega-menu-megamenu.mega-toggle-on ul.mega-sub-menu {
    display: block;
  }
  #mega-menu-wrap-main_menu .mega-menu-toggle.mega-menu-open + #mega-menu-main_menu {
    display: block;
  }
}
#mega-menu-wrap-main_menu .mega-menu-toggle {
  /** Push menu onto new line **/
}
#mega-menu-wrap-main_menu .mega-menu-toggle .mega-toggle-block-1:before {
  content: '\f333';
  font-family: 'dashicons';
  font-size: 24px;
  margin: 0 0 0 5px;
  color: #fff;
}
#mega-menu-wrap-main_menu .mega-menu-toggle.mega-menu-open .mega-toggle-block-1:before {
  content: '\f153';
}
#mega-menu-wrap-main_menu .mega-menu-toggle .mega-toggle-block-1:after {
  content: 'MENU';
  color: #fff;
  float: left;
}
#mega-menu-wrap-main_menu .mega-menu-toggle.mega-menu-open .mega-toggle-block-1:after {
  content: 'MENU';
}
#mega-menu-wrap-main_menu {
  clear: both;
}
