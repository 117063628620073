body {
    font-family: "Montserrat", sans-serif;
}

img.wp-smiley, img.emoji {
    display: inline !important;
    border: none !important;
    box-shadow: none !important;
    height: 1em !important;
    width: 1em !important;
    margin: 0 0.07em !important;
    vertical-align: -0.1em !important;
    background: none !important;
    padding: 0 !important;
}

.metaslider.ms-theme-disjoint .flexslider ol.flex-control-nav:not(.flex-control-thumbs) li a, .metaslider.ms-theme-disjoint ul.rslides_tabs li a, .metaslider.ms-theme-disjoint div.slider-wrapper .nivo-controlNav:not(.nivo-thumbs-enabled) a, .metaslider.ms-theme-disjoint .coin-slider .cs-buttons a {
    max-width: 30px !important;
}

.metaslider.ms-theme-disjoint .flexslider ol.flex-control-nav:not(.flex-control-thumbs), .metaslider.ms-theme-disjoint ul.rslides_tabs, .metaslider.ms-theme-disjoint div.slider-wrapper .nivo-controlNav:not(.nivo-thumbs-enabled) {
    bottom: 0px !important
}

.kk-star-ratings {
    width: 120px;
}

.kk-star-ratings .kksr-stars a {
    width: 24px;
}

.kk-star-ratings .kksr-stars, .kk-star-ratings .kksr-stars .kksr-fuel, .kk-star-ratings .kksr-stars a {
    height: 24px;
}

.kk-star-ratings .kksr-star.gray {
    background-image: url(wp-content/plugins/kk-star-ratings/gray.png);
}

.kk-star-ratings .kksr-star.yellow {
    background-image: url(https://thamtutamgia.com/wp-content/plugins/kk-star-ratings/yellow.png);
}

.kk-star-ratings .kksr-star.orange {
    background-image: url(https://thamtutamgia.com/wp-content/plugins/kk-star-ratings/orange.png);
}

.header-wrapper {
    background: #000
}

.main.menu {
    margin-bottom: 0
}

#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item > a.mega-menu-link, a {
    color: #F7C959
}

#mega-menu-wrap-main_menu, .main.menu, .footer-wrapper {
    background: #000
}

body {
    background: #000;
    color: #fff
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #fff
}

.color-6 {
    background: #F7C959;
    color: #333;
}

.border-6, .relate_news_list h3.tittle, .nav-tabs {
    border-color: #F7C959
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    background: #F7C959
}

.title-wrapper h2 span {
    padding: 8px 10px;
}

.relate_news_list ul li a {
    color: #F7C959
}

div#news .content_category_list .description h4 {
    color: #fff;
}

section.header-wrapper.clearfix img {
    max-width: 100% !important
}

.f_logo {
    max-width: 168px !important;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: #000
}

body {
    font-family: "Montserrat", sans-serif;
}

div#news .list_new {
    border-bottom: 1px dashed #727272;
}

#main-content {
    overflow: hidden;
}

.wp-caption {
    max-width: 100%
}

.content_page span {
    color: #fff !important;
    background: none !important
}

.aligncenter {
    display: block;
    margin: 15px auto;
    text-align: center;
}

#mega-menu-wrap-main_menu .mega-menu-toggle .mega-toggle-block-right {
    float: none;
}

.img_post {
    position: relative;
    overflow: hidden;
}

.content-tham-tu ul {
    padding-left: 20px;
}

.plguin_seo h2.plugin_seo_descriptio {
    color: #fff
}

#mega-menu-wrap-main_menu .mega-menu-toggle .mega-toggle-block-1:after {
    color: #333;
    font-weight: bold;
}

.content-tham-tu ul li {
    list-style: inside
}

.img_post img {
    width: 100%;
}

.img_post:hover a:nth-child(2) {
    display: flex;
}

.box_2 img {
    border-radius: 20px;
    max-height: 260px;
    width: auto;
}

.img_post a:nth-child(2) {
    border-radius: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: none;
    background: #8F663250;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

.ttn li:nth-child(1) a img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 120%;
    max-width: 1000%;
    width: auto;
    transform: translate(-50%, -50%);
}

.ttn li:nth-child(1) a {
    display: block;
    overflow: hidden;
    position: relative;
    padding-top: 65%;
    border-radius: 12px;
}

.img_post a:nth-child(2) span {
    display: inline-block;
    padding: 8px 10px;
    border-radius: 8px;
    border: 1px solid #fff;
    color: #fff;
}

#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu {
    width: 268px;
}

#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item a.mega-menu-link:hover, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item a.mega-menu-link:focus, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item a.mega-menu-link {
    background: none
}

.page_title h1 {
    border: 0px;
}

#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item a.mega-menu-link {
    line-height: 1.8;
    padding: 6px 10px;
}

#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item a.mega-menu-link:hover {
    background: #8F6632;
}

#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-flyout ul.mega-sub-menu {
    background: var(--Nn-ti, linear-gradient(279deg, #8F6632 -1.49%, #191003 87.08%));
}

.no_bg {
    background: none !important
}

.box_4 input {
    background: none;
    border: 0px;
    border-bottom: #F9F8F8 1px solid;
    display: block;
    padding: 8px 0;
    margin-bottom: 15px;
    width: 100%;
}

.box_4 h3 {
    font-size: 15px;
    font-weight: normal;
}

.box_4 {
    background: url(/builds/images/Rectangle-34624853.jpg) no-repeat center;
    background-size: cover
}

.max-600 {
    max-width: 600px;
    margin: auto;
    padding: 50px;
    text-align: center;
    background: url(/builds/images/g2.png);
}

.box_3 ul img {
    float: left;
    position: absolute;
    left: 20px;
    top: 20px;
}

.box_3 ul {
    position: relative;
}

.box_3 ul h3 {
    font-size: 24px;
    border-bottom: 1px solid #FFC67E;
    padding-bottom: 10px;
}

.box_3 ul {
    border-radius: 27px;

    background: #2E2E2E;
    padding: 20px;
    margin-top: 20px;
    padding-left: 240px;
}

.box_2 {
    position: relative;
    background: url(/builds/images/b_2.png) no-repeat center bottom;
    background-size: 100% auto
}

.b_2_svg svg {
    fill: var(--Nn-ti, linear-gradient(279deg, #8F6632 -1.49%, #191003 87.08%));
}

ul {
    padding-left: 0
}

.btn_a {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid #fff;
    color: #fff
}

.img_1 {
    padding-bottom: 80px;
    background: url(/builds/images/bbb.png) no-repeat left bottom;
}

.box_2 ul {
    margin-bottom: 20px;
}

.box_2 .col-md-4:nth-child(3n+1) {
    clear: both;
}

.box_2 ul li:nth-child(2) a {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    display: block;
    padding: 20px 10px 10px;
}

.box_2 h2 {
    padding-bottom: 20px;
}

.ih2 {
    display: inline-block;
    width: 49px;
    height: 37px;
    background: url(/builds/images/ih2.png) center;
    margin-right: 10px;
    position: relative;
    top: 8px;
}

.p50 {
    padding: 50px 0
}

.box_1 h1 {
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #C98F47
}

.box_1 h2 {
    background: url(/builds/images/h22.png) no-repeat center;
    background-size: 100% auto;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    line-height: 48px;
    padding-left: 15px;
}

.box_1 h3 {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    color: #F2F2F2;
}

.flex-direction-nav {
    display: none !important
}

@media (max-width: 768px) {
    .title-lh {
        font-size: 24px;
        padding: 15px;
    }

    .content-post {
        text-align: justify;
    }

    .lh-tab .nav-tabs li a {
        padding: 5px 10px;
        font-size: 14px;
    }

    .lh-tab {
        padding: 15px;
    }

    .box-qtx ul li:nth-child(2), .box-qtx ul li:nth-child(3) strong {
        min-height: 10px;
    }

    .p500 {
        padding: 20px;
    }

    .f_text_2 h3 {
        margin-top: 20px !important;
    }

    .dv_content {
        padding: 20px
    }

    .f_1 img {
        position: relative !important;
        float: none;
    }

    .f_logo {
        float: none !important;
        margin-bottom: 15px;
    }

    .f_1 {
        padding-left: 0 !important;
    }

    .bg_img {
        display: none;
    }

    .box-tgv2:before {
        display: none;
    }

    .box-tgv2 {
        padding-left: 15px
    }

    #mega-menu-wrap-main_menu .mega-menu-toggle {
        display: block;
        background: #FFC67E;
        color: #333;
    }

    section.header-wrapper.clearfix img {
        height: 68px !important;
    }

    .banner_all .content-text h1 {
        font-size: 24px;
    }

    .box_1 h2 {
        font-size: 20px;
    }

    .row-flex {
        flex-wrap: wrap;
    }

    .box-quy-trinh-2 .item {
        padding-left: 100px;
    }

    .box_3 ul {
        padding: 20px;
    }

    .box_3 ul img {
        position: relative;
        float: none;
        margin: auto 20px;
    }
}

.f_1 {
    position: relative;
    padding-left: 155px;
}

.f_1 img {
    position: absolute;
    left: 0px;
    top: 10px;
}

.f_text_2 li {
    padding-top: 5px;
}

span.add, span.email, span.hotline {
    background: url(/builds/images/add.png) no-repeat center;
    width: 14px;
    height: 21px;
    display: inline-block;
    margin-right: 10px;
}

span.email {
    background-image: url(/builds/images/email.png);
    width: 21px;
    height: 16px;
}

span.hotline {
    background-image: url(/builds/images/phone.png);
    width: 21px;
    height: 21px;
}

.f_logo {
    float: left;
    margin-right: 15px;
}

.row-flex {
    display: flex;
    gap: 10px;
    align-items: center;
}

.f_text_2 h3 {
    font-size: 16px;
    margin-top: 0px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: Montserrat;
}

.f_text_3 {
    min-width: 260px;
}

.f_text_3 ul {
    padding-bottom: 20px;
}

.f_text_3 ul li {
    padding-top: 10px;
    display: flex;
    align-items: center;
}

.metaslider {
    overflow: hidden;
}

.footer-wrapper ul {
    padding-left: 0
}

.flickr-gallery.clearfix .map_google {
    padding: 0;
    border: 0;
}

.categories-widget ul.category-list li a {
    border-bottom: 0
}

body .footer-wrapper .widget-title h2 {
    border-bottom: 0;
    text-transform: none;
    box-shadow: none;
}

#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item.mega-current-menu-ancestor > a.mega-menu-link, #mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item.mega-current-page-ancestor > a.mega-menu-link {
    border-radius: 10px;
    background: var(--Gradient, linear-gradient(92deg, #FFC67E 0.27%, #FFDFC2 95.42%));
}

#mega-menu-wrap-main_menu #mega-menu-main_menu {
    text-align: center;
}

#mega-menu-wrap-main_menu #mega-menu-main_menu > li.mega-menu-item > a.mega-menu-link {
    color: #fff;
    text-transform: none;
}

#fp-container {
    display: none
}

.support {
    position: fixed;
    top: 40%;
    right: -170px;
    z-index: 9999;
}

.support-item {
    width: 220px;
    margin-bottom: 5px;
    border-radius: 99px 0 0 99px;
    padding: 5px;
    font-size: 120%;
    transition-duration: 1s !important;
}

.support-item img {
    border-radius: 100%;
    padding-right: 10px;
    width: 50px;
}

.support-item:hover {
    margin-left: -170px !important;
    transition-duration: 1s !important;
}

.wp-block-button__link {
    color: #fff;
    background-color: #32373c;
    border-radius: 9999px;
    box-shadow: none;
    text-decoration: none;
    padding: calc(.667em + 2px) calc(1.333em + 2px);
    font-size: 1.125em
}

.wp-block-file__button {
    background: #32373c;
    color: #fff;
    text-decoration: none
}

body {
    --wp--preset--color--black: #000000;
    --wp--preset--color--cyan-bluish-gray: #abb8c3;
    --wp--preset--color--white: #ffffff;
    --wp--preset--color--pale-pink: #f78da7;
    --wp--preset--color--vivid-red: #cf2e2e;
    --wp--preset--color--luminous-vivid-orange: #ff6900;
    --wp--preset--color--luminous-vivid-amber: #fcb900;
    --wp--preset--color--light-green-cyan: #7bdcb5;
    --wp--preset--color--vivid-green-cyan: #00d084;
    --wp--preset--color--pale-cyan-blue: #8ed1fc;
    --wp--preset--color--vivid-cyan-blue: #0693e3;
    --wp--preset--color--vivid-purple: #9b51e0;
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, rgb(155, 81, 224) 100%);
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, rgb(122, 220, 180) 0%, rgb(0, 208, 130) 100%);
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, rgb(207, 46, 46) 100%);
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, rgb(238, 238, 238) 0%, rgb(169, 184, 195) 100%);
    --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
    --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
    --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 50%, rgb(107, 0, 62) 100%);
    --wp--preset--gradient--luminous-dusk: linear-gradient(135deg, rgb(255, 203, 112) 0%, rgb(199, 81, 192) 50%, rgb(65, 88, 208) 100%);
    --wp--preset--gradient--pale-ocean: linear-gradient(135deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 50%, rgb(51, 167, 181) 100%);
    --wp--preset--gradient--electric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
    --wp--preset--gradient--midnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
    --wp--preset--font-size--small: 13px;
    --wp--preset--font-size--medium: 20px;
    --wp--preset--font-size--large: 36px;
    --wp--preset--font-size--x-large: 42px;
    --wp--preset--spacing--20: 0.44rem;
    --wp--preset--spacing--30: 0.67rem;
    --wp--preset--spacing--40: 1rem;
    --wp--preset--spacing--50: 1.5rem;
    --wp--preset--spacing--60: 2.25rem;
    --wp--preset--spacing--70: 3.38rem;
    --wp--preset--spacing--80: 5.06rem;
    --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
    --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
    --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
    --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1), 6px 6px rgba(0, 0, 0, 1);
    --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
}

:where(.is-layout-flex) {
    gap: 0.5em;
}

:where(.is-layout-grid) {
    gap: 0.5em;
}

body .is-layout-flow > .alignleft {
    float: left;
    margin-inline-start: 0;
    margin-inline-end: 2em;
}

body .is-layout-flow > .alignright {
    float: right;
    margin-inline-start: 2em;
    margin-inline-end: 0;
}

body .is-layout-flow > .aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
}

body .is-layout-constrained > .alignleft {
    float: left;
    margin-inline-start: 0;
    margin-inline-end: 2em;
}

body .is-layout-constrained > .alignright {
    float: right;
    margin-inline-start: 2em;
    margin-inline-end: 0;
}

body .is-layout-constrained > .aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
}

body .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
    max-width: var(--wp--style--global--content-size);
    margin-left: auto !important;
    margin-right: auto !important;
}

body .is-layout-constrained > .alignwide {
    max-width: var(--wp--style--global--wide-size);
}

body .is-layout-flex {
    display: flex;
}

body .is-layout-flex {
    flex-wrap: wrap;
    align-items: center;
}

body .is-layout-flex > * {
    margin: 0;
}

body .is-layout-grid {
    display: grid;
}

body .is-layout-grid > * {
    margin: 0;
}

:where(.wp-block-columns.is-layout-flex) {
    gap: 2em;
}

:where(.wp-block-columns.is-layout-grid) {
    gap: 2em;
}

:where(.wp-block-post-template.is-layout-flex) {
    gap: 1.25em;
}

:where(.wp-block-post-template.is-layout-grid) {
    gap: 1.25em;
}

.has-black-color {
    color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-color {
    color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-color {
    color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-color {
    color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-color {
    color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-color {
    color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-color {
    color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-color {
    color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-color {
    color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-color {
    color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-color {
    color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-color {
    color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-background-color {
    background-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-background-color {
    background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-background-color {
    background-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-background-color {
    background-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-background-color {
    background-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-background-color {
    background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-background-color {
    background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-background-color {
    background-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-background-color {
    background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-background-color {
    background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-background-color {
    background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-background-color {
    background-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-border-color {
    border-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-border-color {
    border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-border-color {
    border-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-border-color {
    border-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-border-color {
    border-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-border-color {
    border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-border-color {
    border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-border-color {
    border-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-border-color {
    border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-border-color {
    border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-border-color {
    border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-border-color {
    border-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
    background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
}

.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
    background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
}

.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
    background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
}

.has-luminous-vivid-orange-to-vivid-red-gradient-background {
    background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
}

.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
    background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
}

.has-cool-to-warm-spectrum-gradient-background {
    background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}

.has-blush-light-purple-gradient-background {
    background: var(--wp--preset--gradient--blush-light-purple) !important;
}

.has-blush-bordeaux-gradient-background {
    background: var(--wp--preset--gradient--blush-bordeaux) !important;
}

.has-luminous-dusk-gradient-background {
    background: var(--wp--preset--gradient--luminous-dusk) !important;
}

.has-pale-ocean-gradient-background {
    background: var(--wp--preset--gradient--pale-ocean) !important;
}

.has-electric-grass-gradient-background {
    background: var(--wp--preset--gradient--electric-grass) !important;
}

.has-midnight-gradient-background {
    background: var(--wp--preset--gradient--midnight) !important;
}

.has-small-font-size {
    font-size: var(--wp--preset--font-size--small) !important;
}

.has-medium-font-size {
    font-size: var(--wp--preset--font-size--medium) !important;
}

.has-large-font-size {
    font-size: var(--wp--preset--font-size--large) !important;
}

.has-x-large-font-size {
    font-size: var(--wp--preset--font-size--x-large) !important;
}

.wp-block-navigation a:where(:not(.wp-element-button)) {
    color: inherit;
}

:where(.wp-block-post-template.is-layout-flex) {
    gap: 1.25em;
}

:where(.wp-block-post-template.is-layout-grid) {
    gap: 1.25em;
}

:where(.wp-block-columns.is-layout-flex) {
    gap: 2em;
}

:where(.wp-block-columns.is-layout-grid) {
    gap: 2em;
}

.wp-block-pullquote {
    font-size: 1.5em;
    line-height: 1.6;
}
.slider-3 img {
    height: 156px;
    width: auto;
}
@media (max-width: 768px) {
    .ttn ul li:nth-child(2) a {
        font-size: 16px !important
    }

    .ttn:nth-child(2n+1) {
        clear: both;
    }

    .ttn {
        float: left;
        width: 50%;
    }

    h2, .h2 {
        font-size: 24px;
    }

    .slider-3 img {
        max-height: 72px;
        width: auto;
    }
}

.box_dt {
    background: linear-gradient(82deg, #8F6632 11.71%, #191003 108.1%);
    padding: 50px 0
}

@media screen and ( max-width: 720px ) {
    #dpsp-floating-sidebar.dpsp-hide-on-mobile {
        display: none !important;
    }
}
