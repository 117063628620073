.color-1 {background: #151551;}
.color-2  {background: #5fa03f;}
.color-3 {background: #0099cc;}
.color-4 {background: #e89319;}
.color-5 {background: #72347d;}
.color-6 {background: #fd7d1e;}
.color-7 {background: #E71838;}
.color-8 {background: #111111;}
.color-9 {background: #fd0005;}
.color-10 {background: #ff6642;}

.border-1 {border-bottom: 2px solid #151551;}
.border-2 {border-bottom: 2px solid #5fa03f;}
.border-3 {border-bottom: 2px solid #0099cc;}
.border-4 {border-bottom: 2px solid #e89319;}
.border-5 {border-bottom: 2px solid #72347d;}
.border-6 {border-bottom: 2px solid #fd7d1e;}
.border-7 {border-bottom: 2px solid #E71838;}
.border-8 {border-bottom: 2px solid #111111;}
.border-9 {border-bottom: 2px solid #fd0005;}

.border__1 {border-top: 4px solid #151551;}
.border__2 {border-top: 4px solid #5fa03f;}
.border__3 {border-top: 4px solid #0099cc;}
.border__4 {border-top: 4px solid #e89319;}
.border__5 {border-top: 4px solid #72347d;}
.border__6 {border-top: 4px solid #ffcc00;}
.border__7 {border-top: 4px solid #E71838;}
.border__8 {border-top: 4px solid #111111;}
@media (max-width: 768px) {
  .border__1 ,
  .border__2 ,
  .border__3 ,
  .border__4 ,
  .border__5 ,
  .border__6 ,
  .border__7 ,
  .border__8 {border-top: 0;}
}