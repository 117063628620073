
.kk-star-ratings {
	width: 120px;
	min-height: 48px;
}
.kk-star-ratings.top-left,
.kk-star-ratings.top-right {
	margin-top: 3px;
}
.kk-star-ratings.lft {
	float: left;
	margin-right: 10px;
}
.kk-star-ratings.rgt{
	float: right;
	margin-left: 10px;
}
.kk-star-ratings .kksr-stars {
	height: 24px;
	position: relative;
}
.kk-star-ratings .kksr-star {
	background-position: 0px 0px;
}
.kk-star-ratings .kksr-star.gray {
	background-image: url(gray.png);
}
.kk-star-ratings .kksr-star.yellow {
	background-image: url(yellow.png);
}
.kk-star-ratings .kksr-star.orange {
	background-image: url(orange.png);
}
.kk-star-ratings .kksr-stars .kksr-fuel {
	height: 24px;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 0;
}
.kk-star-ratings .kksr-stars a {
	width: 24px;
	height: 24px;
	float: left;
	position: relative;
	z-index: 1;
	text-decoration: none;
	border: 0;
}
.kk-star-ratings .kksr-stars a:focus {
	text-decoration: none;
	border: 0;
	outline: none;
}
.kk-star-ratings .kksr-legend {
	font-size: 12px;
	color: #999;
	text-align: center;
	line-height: 18px;
	padding: 3px;
	font-family: Arial, Helvetica, sans-serif;
}
.kk-star-ratings .kksr-legend .kksr-title {
	display: none;
}

.kk-star-ratings .kksr-stars,
.kk-star-ratings .kksr-stars .kksr-fuel,
.kk-star-ratings .kksr-stars a {
	background-color: transparent !important;
}
