/*
Default style for WP-PageNavi plugin

http://wordpress.org/extend/plugins/wp-pagenavi/
*/

.wp-pagenavi {
	clear: both;
}

.wp-pagenavi a, .wp-pagenavi span {
	text-decoration: none;
	border: 1px solid #BFBFBF;
	padding: 3px 5px;
	margin: 2px;
}

.wp-pagenavi a:hover, .wp-pagenavi span.current {
	border-color: #000;
}

.wp-pagenavi span.current {
	font-weight: bold;
}
