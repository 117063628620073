/*****************************************/
/* General: Fonts
/*****************************************/
@font-face {
	font-family: 'Social Pug';
	src:url('../fonts/socialpugbb91.eot?gd6mr8');
	src:url('../fonts/socialpugd41d.eot?#iefix') format('embedded-opentype'),
		url('../fonts/socialpug.woff') format('woff'),
		url('../fonts/socialpug.ttf') format('truetype'),
		url('../fonts/socialpug.svg#socialpug') format('svg');
	font-weight: normal;
	font-style: normal;
}


/*****************************************/
/* General: Icons
/*****************************************/
.dpsp-network-btn:before {
	font-family: "Social Pug";
	font-style: normal;
	font-weight: normal;

	display: inline-block;
	text-decoration: inherit;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
}

.dpsp-facebook:before {
	content: '\e800';
}

.dpsp-twitter:before {
	content: '\e801';
}

.dpsp-google-plus:before {
	content: '\e802';
}

.dpsp-pinterest:before {
	content: '\e803';
}

/*****************************************/
/* Floating Sidebar Buttons
/*****************************************/
#dpsp-floating-sidebar {
	position: fixed;
	top: 30%;
	z-index: 9998;
}

#dpsp-floating-sidebar.dpsp-position-left {
	left: 0;
}

#dpsp-floating-sidebar.dpsp-position-right {
	right: 0;
}

.dpsp-networks-btns-wrapper.dpsp-networks-btns-sidebar li {
	float: none;
	margin-left: 0;
}

.dpsp-networks-btns-wrapper.dpsp-networks-btns-sidebar .dpsp-network-btn {
	padding: 0;
	width: 40px;
}

.dpsp-networks-btns-wrapper.dpsp-networks-btns-sidebar .dpsp-network-btn:before {
	border-color: transparent !important;
}

.dpsp-networks-btns-wrapper.dpsp-networks-btns-sidebar .dpsp-network-btn.dpsp-has-count:before {
	height: 22px;
	line-height: 22px;
}

.dpsp-button-hover .dpsp-networks-btns-wrapper.dpsp-networks-btns-sidebar .dpsp-network-btn:hover:before {
	color: #fff;
}


/* Sidebar buttons option - rounded */
.dpsp-shape-rounded .dpsp-networks-btns-sidebar .dpsp-network-btn,
.dpsp-shape-rounded .dpsp-networks-btns-sidebar .dpsp-network-btn:before {
	border-radius: 0;
}

.dpsp-position-left.dpsp-shape-rounded .dpsp-networks-btns-sidebar .dpsp-network-btn.dpsp-first,
.dpsp-position-left.dpsp-shape-rounded .dpsp-networks-btns-sidebar .dpsp-network-btn.dpsp-first:before {
	-webkit-border-top-right-radius: 5px;
	-moz-border-radius-topright: 5px;
	border-top-right-radius: 5px;
}

.dpsp-position-left.dpsp-shape-rounded .dpsp-networks-btns-sidebar .dpsp-network-btn.dpsp-last,
.dpsp-position-left.dpsp-shape-rounded .dpsp-networks-btns-sidebar .dpsp-network-btn.dpsp-last:before {
	-webkit-border-bottom-right-radius: 5px;
	-moz-border-radius-bottomright: 5px;
	border-bottom-right-radius: 5px;
}

.dpsp-position-left.dpsp-shape-rounded.dpsp-bottom-spacing .dpsp-networks-btns-sidebar .dpsp-network-btn,
.dpsp-position-left.dpsp-shape-rounded.dpsp-bottom-spacing .dpsp-networks-btns-sidebar .dpsp-network-btn:before {
	-webkit-border-top-right-radius: 5px;
	-moz-border-radius-topright: 5px;
	border-top-right-radius: 5px;
	-webkit-border-bottom-right-radius: 5px;
	-moz-border-radius-bottomright: 5px;
	border-bottom-right-radius: 5px;
}


.dpsp-position-right.dpsp-shape-rounded .dpsp-networks-btns-sidebar .dpsp-network-btn.dpsp-first,
.dpsp-position-right.dpsp-shape-rounded .dpsp-networks-btns-sidebar .dpsp-network-btn.dpsp-first:before {
	-webkit-border-top-left-radius: 5px;
	-moz-border-radius-topleft: 5px;
	border-top-left-radius: 5px;
}

.dpsp-position-right.dpsp-shape-rounded .dpsp-networks-btns-sidebar .dpsp-network-btn.dpsp-last,
.dpsp-position-right.dpsp-shape-rounded .dpsp-networks-btns-sidebar .dpsp-network-btn.dpsp-last:before {
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-bottom-left-radius: 5px;
}

.dpsp-position-right.dpsp-shape-rounded.dpsp-bottom-spacing .dpsp-networks-btns-sidebar .dpsp-network-btn,
.dpsp-position-right.dpsp-shape-rounded.dpsp-bottom-spacing .dpsp-networks-btns-sidebar .dpsp-network-btn:before {
	-webkit-border-top-left-radius: 5px;
	-moz-border-radius-topleft: 5px;
	border-top-left-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-bottom-left-radius: 5px;
}

.dpsp-shape-rounded.dpsp-has-spacing .dpsp-networks-btns-sidebar .dpsp-network-btn:before {
	border-radius: 5px;
}

/* Sidebar buttons option - circle */
#dpsp-floating-sidebar.dpsp-position-left.dpsp-shape-circle {
	left: 12px;
}

#dpsp-floating-sidebar.dpsp-position-right.dpsp-shape-circle {
	right: 10px;
}

/* Sidebar buttons bottom spacing */
.dpsp-bottom-spacing .dpsp-networks-btns-sidebar .dpsp-network-btn {
	margin-bottom: 6px;
}

.dpsp-shape-rounded.dpsp-bottom-spacing .dpsp-networks-btns-sidebar .dpsp-network-btn {
	-webkit-border-top-right-radius: 5px;
	-moz-border-radius-topright: 5px;
	border-top-right-radius: 5px;

	-webkit-border-bottom-right-radius: 5px;
	-moz-border-radius-bottomright: 5px;
	border-bottom-right-radius: 5px;
}

/*
 * Sidebar buttons style 2
 */
#dpsp-floating-sidebar.dpsp-style-2 .dpsp-network-btn:before {
	background: none;
}


/*****************************************/
/* Content Buttons
/*****************************************/
#dpsp-content-top { margin-bottom: 1.2em; }
#dpsp-content-bottom { margin-top: 1.2em; }

.dpsp-share-text { font-weight: bold; margin-bottom: 10px; }

/**************************************************/
/* General button styling, for content and other
/**************************************************/
.dpsp-networks-btns-wrapper {
	list-style: none !important;
	padding: 0 !important;
	margin: 0 !important;
}

.dpsp-networks-btns-wrapper:after {
	content: '';
	display: block;
	height: 0;
	clear: both;
}

.dpsp-networks-btns-wrapper li {
	float: left;
	overflow: hidden;

	-moz-transition: all 0.15s ease-in; 
	-webkit-transition: all 0.15s ease-in; 
	-o-transition: all 0.15s ease-in;
	transition: all 0.15s ease-in;
}

.dpsp-networks-btns-wrapper li:first-child {
	margin-left: 0 !important;
}

/*
 * Share button
 */
.dpsp-networks-btns-wrapper .dpsp-network-btn {
	position: relative;

	display: block;
	text-decoration: none !important;
	text-transform: unset !important;
	height: 40px;
	min-width: 40px;

	font-family: 'Arial';
	font-weight: bold;
	font-size: 14px;
	line-height: 36px;
	vertical-align: middle;

	padding: 0 25px 0 48px;
	border: 2px solid;
	max-height: 40px;

	width: 100%;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	-moz-transition: all 0.15s ease-in;
	-webkit-transition: all 0.15s ease-in;
	-o-transition: all 0.15s ease-in;
	transition: all 0.15s ease-in;

	box-shadow: none;

	cursor: pointer;
}

.dpsp-networks-btns-wrapper .dpsp-network-btn:hover {
	border: 2px solid;
	box-shadow: none;
}

.dpsp-networks-btns-wrapper .dpsp-network-btn:focus {
	border: 2px solid;
	box-shadow: none;
}

.dpsp-networks-btns-wrapper .dpsp-network-btn:after {
	content: '';
	display: block;
	height: 0;
	clear: both;
}


/*
 * Button icon
 */
.dpsp-networks-btns-wrapper .dpsp-network-btn:before {
	position: absolute;
	top: -2px;
	left: -2px;
	float: left;
	display: block;
	font-size: 14px;
	line-height: 36px;
	width: 40px;
	text-align: center;
	vertical-align: middle;
	border: 2px solid;

	-moz-box-sizing: border-box;
	box-sizing: border-box;

	-moz-transition: all 0.15s ease-in; 
	-webkit-transition: all 0.15s ease-in; 
	-o-transition: all 0.15s ease-in;
	transition: all 0.15s ease-in;
}


/*
 * Buttons with no labels
 */
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-no-label {
	padding: 0 18px;
}

.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-no-label:before {
	width: auto;
	right: -2px;
}


/*
 * Button label
 */
.dpsp-has-buttons-count .dpsp-networks-btns-wrapper .dpsp-network-label {
	margin-right: 10px;
}

.dpsp-networks-btns-wrapper .dpsp-network-btn .dpsp-network-count {
	font-size: 13px;
}

.dpsp-networks-btns-wrapper.dpsp-networks-btns-sidebar .dpsp-network-btn .dpsp-network-count {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	margin-left: 0;
	font-size: 11px;
	text-align: center;
	height: 20px;
	line-height: 20px;
}

.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-no-label .dpsp-network-label-wrapper {
	display: none;
}


/*
 * Buttons columns
 */
.dpsp-column-1 .dpsp-networks-btns-wrapper li { width: 100%; }
.dpsp-column-1.dpsp-has-spacing .dpsp-networks-btns-wrapper li { margin-right: 0; }

.dpsp-column-2 .dpsp-networks-btns-wrapper li { width: 50%; }
.dpsp-column-2.dpsp-has-spacing .dpsp-networks-btns-wrapper li { width: 49%; }
.dpsp-column-2 .dpsp-networks-btns-wrapper li:nth-child(2n) { margin-right: 0; }

.dpsp-column-3 .dpsp-networks-btns-wrapper li { width: 33.3333%; }
.dpsp-column-3.dpsp-has-spacing .dpsp-networks-btns-wrapper li { width: 32%; }
.dpsp-column-3 .dpsp-networks-btns-wrapper li:nth-child(3n) { margin-right: 0; }

.dpsp-column-4 .dpsp-networks-btns-wrapper li { width: 25%; }
.dpsp-column-4.dpsp-has-spacing .dpsp-networks-btns-wrapper li { width: 23.5%; }
.dpsp-column-4 .dpsp-networks-btns-wrapper li:nth-child(4n) { margin-right: 0; }

.dpsp-column-2 .dpsp-networks-btns-wrapper .dpsp-network-btn,
.dpsp-column-3 .dpsp-networks-btns-wrapper .dpsp-network-btn,
.dpsp-column-4 .dpsp-networks-btns-wrapper .dpsp-network-btn { padding-right: 10px; }

/*
 * Buttons width no labels
 */
.dpsp-no-labels .dpsp-networks-btns-wrapper .dpsp-network-btn { padding: 0; text-align: center; }
.dpsp-no-labels:not(.dpsp-column-auto) .dpsp-networks-btns-wrapper .dpsp-network-btn:before { position: relative; left: 0; float: none; display: inline-block; }


/* 
 * Buttons colors 
 */
 /* Facebook */
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-facebook:before,
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-facebook {
	border-color: #3a579a;
	color: #3a579a;
}
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-facebook:hover:before,
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-facebook:hover {
	border-color: #334d87;
	color: #334d87;
}
.dpsp-has-icon-background .dpsp-networks-btns-wrapper .dpsp-facebook:before,
.dpsp-has-button-background .dpsp-networks-btns-wrapper .dpsp-facebook {
	background: #3a579a;
	color: #fff;
}
.dpsp-icon-hover .dpsp-networks-btns-wrapper .dpsp-facebook:hover:before,
.dpsp-button-hover .dpsp-networks-btns-wrapper .dpsp-facebook:hover,
.dpsp-has-icon-background .dpsp-networks-btns-wrapper .dpsp-facebook:hover:before,
.dpsp-has-button-background .dpsp-networks-btns-wrapper .dpsp-facebook:hover {
	background: #334d87;
	color: #fff;
}
.dpsp-has-icon-dark .dpsp-networks-btns-wrapper .dpsp-facebook:before {
	background: #334d87;
	border-color: #334d87;
}

/* Twitter */
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-twitter:before,
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-twitter {
	border-color: #00abf0;
	color: #00abf0;
}
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-twitter:hover:before,
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-twitter:hover {
	border-color: #0099d7;
	color: #0099d7;
}
.dpsp-has-icon-background .dpsp-networks-btns-wrapper .dpsp-twitter:before,
.dpsp-has-button-background .dpsp-networks-btns-wrapper .dpsp-twitter {
	background: #00abf0;
	color: #fff;
}
.dpsp-icon-hover .dpsp-networks-btns-wrapper .dpsp-twitter:hover:before,
.dpsp-button-hover .dpsp-networks-btns-wrapper .dpsp-twitter:hover,
.dpsp-has-icon-background .dpsp-networks-btns-wrapper .dpsp-twitter:hover:before,
.dpsp-has-button-background .dpsp-networks-btns-wrapper .dpsp-twitter:hover {
	background: #0099d7;
	color: #fff;
}
.dpsp-has-icon-dark .dpsp-networks-btns-wrapper .dpsp-twitter:before {
	background: #0099d7;
	border-color: #0099d7;
}


/* Google+ */
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-google-plus:before,
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-google-plus {
	border-color: #df4a32;
	color: #df4a32;
}
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-google-plus:hover:before,
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-google-plus:hover {
	border-color: #d63a21;
	color: #d63a21;
}
.dpsp-has-icon-background .dpsp-networks-btns-wrapper .dpsp-google-plus:before,
.dpsp-has-button-background .dpsp-networks-btns-wrapper .dpsp-google-plus {
	background: #df4a32;
	color: #fff;
}
.dpsp-icon-hover .dpsp-networks-btns-wrapper .dpsp-google-plus:hover:before,
.dpsp-button-hover .dpsp-networks-btns-wrapper .dpsp-google-plus:hover,
.dpsp-has-icon-background .dpsp-networks-btns-wrapper .dpsp-google-plus:hover:before,
.dpsp-has-button-background .dpsp-networks-btns-wrapper .dpsp-google-plus:hover {
	background: #d63a21;
	color: #fff;
}
.dpsp-has-icon-dark .dpsp-networks-btns-wrapper .dpsp-google-plus:before {
	background: #d63a21;
	border-color: #d63a21;
}


/* Pinterest */
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-pinterest:before,
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-pinterest {
	border-color: #c92228;
	color: #c92228;
}
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-pinterest:hover:before,
.dpsp-networks-btns-wrapper .dpsp-network-btn.dpsp-pinterest:hover {
	border-color: #b31e24;
	color: #b31e24;
}
.dpsp-has-icon-background .dpsp-networks-btns-wrapper .dpsp-pinterest:before,
.dpsp-has-button-background .dpsp-networks-btns-wrapper .dpsp-pinterest {
	background: #c92228;
	color: #fff;
}
.dpsp-icon-hover .dpsp-networks-btns-wrapper .dpsp-pinterest:hover:before,
.dpsp-button-hover .dpsp-networks-btns-wrapper .dpsp-pinterest:hover,
.dpsp-has-icon-background .dpsp-networks-btns-wrapper .dpsp-pinterest:hover:before,
.dpsp-has-button-background .dpsp-networks-btns-wrapper .dpsp-pinterest:hover {
	background: #b31e24;
	color: #fff;
}
.dpsp-has-icon-dark .dpsp-networks-btns-wrapper .dpsp-pinterest:before {
	background: #b31e24;
	border-color: #b31e24;
}

/* Sidebar needs color white for icons */
.dpsp-has-button-background .dpsp-networks-btns-wrapper.dpsp-networks-btns-sidebar .dpsp-network-btn:before {
	color: #fff;
}

/* 
 * Buttons Shapes
 */
.dpsp-shape-rounded .dpsp-network-btn,
.dpsp-shape-rounded .dpsp-network-btn:before {
	-webkit-border-top-left-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.dpsp-shape-rounded .dpsp-network-btn,
.dpsp-shape-rounded .dpsp-no-label.dpsp-network-btn:before {
	-webkit-border-top-right-radius: 5px;
	-webkit-border-bottom-right-radius: 5px;
	-moz-border-radius-topright: 5px;
	-moz-border-radius-bottomright: 5px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.dpsp-shape-circle .dpsp-network-btn { border-radius: 20px; }
.dpsp-shape-circle .dpsp-network-btn:before { border-radius: 20px; overflow: hidden; }


/* 
 * Buttons Margins/Spacings
 */
.dpsp-has-spacing .dpsp-networks-btns-wrapper li { margin-bottom: 10px; margin-right: 2%; margin-left: 0 !important; }


/*
 * Buttons on mobile
 */
@media screen and ( max-width : 720px ) {
	.dpsp-hide-on-mobile {
		display: none;
	}
}


/*****************************************/
/* Button styles
/*****************************************/

/*
 * Buttons Style 1
 */
.dpsp-button-style-1 .dpsp-network-btn { color: #fff !important; padding-left: 38px; }


/*****************************************/
/* Total shares
/*****************************************/
.dpsp-total-share-wrapper { 
	font-family: 'Arial';
	margin-top: 10px;
	margin-bottom: 10px;
	line-height: 1.345;
	color: #5d6368;
}

.dpsp-total-share-wrapper span {
	display: inline-block;
	font-size: 12px;
	margin-right: 5px;
}

.dpsp-total-share-wrapper .dpsp-total-share-count {
	font-weight: bold;
	font-size: 15px;
}

#dpsp-floating-sidebar .dpsp-total-share-wrapper {
	text-align: center;
}

#dpsp-floating-sidebar .dpsp-total-share-wrapper span {
	display: block;
	margin-right: 0;
}