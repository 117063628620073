div.wpcf7 {
	margin: 0;
	padding: 0;
}

div.wpcf7-response-output {
	margin: 2em 0.5em 1em;
	padding: 0.2em 1em;
}

div.wpcf7 .screen-reader-response {
	position: absolute;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	width: 1px;
	margin: 0;
	padding: 0;
	border: 0;
}

div.wpcf7-mail-sent-ok {
	border: 2px solid #398f14;
}

div.wpcf7-mail-sent-ng {
	border: 2px solid #ff0000;
}

div.wpcf7-spam-blocked {
	border: 2px solid #ffa500;
}

div.wpcf7-validation-errors {
	border: 2px solid #f7e700;
}

.wpcf7-form-control-wrap {
	position: relative;
}

span.wpcf7-not-valid-tip {
	color: #f00;
	font-size: 1em;
	font-weight: normal;
	display: block;
}

.use-floating-validation-tip span.wpcf7-not-valid-tip {
	position: absolute;
	top: 20%;
	left: 20%;
	z-index: 100;
	border: 1px solid #ff0000;
	background: #fff;
	padding: .2em .8em;
}

span.wpcf7-list-item {
	display: inline-block;
	margin: 0 0 0 1em;
}

span.wpcf7-list-item-label::before,
span.wpcf7-list-item-label::after {
	content: " ";
}

.wpcf7-display-none {
	display: none;
}

div.wpcf7 .ajax-loader {
	visibility: hidden;
	display: inline-block;
	background-image: url('../../images/ajax-loader.gif');
	width: 16px;
	height: 16px;
	border: none;
	padding: 0;
	margin: 0 0 0 4px;
	vertical-align: middle;
}

div.wpcf7 .ajax-loader.is-active {
	visibility: visible;
}

div.wpcf7 div.ajax-error {
	display: none;
}

div.wpcf7 .placeheld {
	color: #888;
}

div.wpcf7 .wpcf7-recaptcha iframe {
	margin-bottom: 0;
}

div.wpcf7 input[type="file"] {
	cursor: pointer;
}

div.wpcf7 input[type="file"]:disabled {
	cursor: default;
}
